import React from "react"
import { Menu, Typography } from "antd"
import { Link, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const { Text } = Typography

const AppNav = () => {
    const { pathname } = useLocation()
    const { role } = useSelector((state) => state.auth)
    return (
        <Menu mode="horizontal" className="appnav" defaultSelectedKeys={pathname === "/" ? "estGuide" : "pricingRule"}>
            <Menu.Item key="estGuide" defaultChecked={true}>
                <Link to="/">
                    <Text style={{ fontWeight: 600 }}>Order Guide</Text>
                </Link>
            </Menu.Item>

            {role === "A" && (
                <Menu.Item key="pricingRule">
                    <Link to="/Pricingrule">
                        <Text style={{ fontWeight: 600 }}>Product Order Rules</Text>
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    )
}

export default AppNav
