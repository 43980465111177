import React, { useState, useEffect, createContext } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Layout, ConfigProvider, theme, Typography, Space, Image, message } from "antd"
import themeConfig from "./config/themeConfig.json"

import { useMsal } from "@azure/msal-react"
import { callMsGraph } from "./config/msal/graph"
import { loginRequest } from "./config/msal/authConfig"

import { useSelector, useDispatch } from "react-redux"
import { setUserProfile } from "./redux/actions/authActions"
import Login from "./components/Login"
import Loading from "./components/Loading"
import AppRoutes from "./route/AppRoutes"
import axios from "axios"
import SessionTimeout from "./components/SessionTimeout"
import AppHeader from "./components/AppHeader"
import "./App.css"
import AppNav from "./components/AppNav"
import { getEndUseCode, getProductFamily, getProuducts } from "./redux/actions/productActions"

const { defaultAlgorithm, darkAlgorithm } = theme
const getPopupContainer = () => document.body
const { components } = themeConfig
const { Content } = Layout
export const AppContext = createContext()
const App = () => {
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [accessToken, setAccessToken] = useState(null)
    const [graphData, setGraphData] = useState(null)
    const [hasAccess, setHasAccess] = useState(true)
    const [authInProgress, setAuthInProgress] = useState(false)
    const [tokenExpiresOn, setTokenExpiresOn] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()

    const { isAuthenticated, user } = useSelector((state) => state.auth)
    const [roles, setRoles] = useState([]);

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal()

    useEffect(() => {
        setAuthInProgress(true)
        const getUserProfile = async () => {
            try {
                // Silently acquires an access token which is then attached to a request for MS Graph
                await instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        setAccessToken(response.accessToken)
                        setTokenExpiresOn(response.expiresOn)
                        setRoles(response?.idTokenClaims?.roles)
                        callMsGraph(response.accessToken).then((response) => setGraphData(response))
                    })
            } catch (error) {
                setAuthInProgress(false)
            }
        }
        getUserProfile()
    }, [instance, inProgress, accounts])

    useEffect(() => {
        const checkUserAccess = async () => {
            if (graphData) {
                // console.log('<<<<<<<<<<<<<accounts<<<<<<<' + JSON.stringify(accounts))
                // console.log('<<<<<<<<<<<<<graphdata<<<<<<<' + JSON.stringify(graphData))
                const { displayName, userPrincipalName, givenName, surname } = graphData

                const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value"

                let userAvatar = null
                window.URL.revokeObjectURL(userAvatar)
                try {
                    const response = await axios(graphEndpoint, {
                        headers: { Authorization: `Bearer ${accessToken}` },
                        responseType: "blob",
                    })

                    if (response.status === 200) userAvatar = window.URL.createObjectURL(response.data)
                } catch (error) {
                    userAvatar = null
                }

                const userProfile = {
                    isAuthenticated: true,
                    role: roles.includes('kapton-admin') ? 'A' : 'U',
                    user: {
                        fullname: displayName,
                        email: userPrincipalName,
                        firstname: givenName,
                        lastname: surname,
                        avatar: userAvatar,
                    },
                    token: accessToken
                }

                //window.URL.revokeObjectURL(userAvtar)
                dispatch(setUserProfile(userProfile))
                setAuthInProgress(false)
            } else {
                setHasAccess(false)
                setAuthInProgress(false)
            }
        }
        checkUserAccess()
    }, [graphData])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getProuducts())
            dispatch(getProductFamily())
            dispatch(getEndUseCode())
        }
    }, [isAuthenticated])

    return (
        <ConfigProvider
            getPopupContainer={getPopupContainer}
            theme={{
                algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                components: components,
            }}
        >
            {!authInProgress && (
                <AppContext.Provider
                    value={{
                        messageApi,
                    }}
                >
                    <Router>
                        {contextHolder}
                        {!isAuthenticated && <Login />}

                        {isAuthenticated && (
                            <Layout style={{ height: "100vh", width: "100vw" }}>
                                <AppHeader user={user && user} />
                                <AppNav />
                                <Content
                                    style={{
                                        overflow: "auto",
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg-img.png)`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <div style={{ margin: "30px 30px" }}>
                                        <AppRoutes />
                                    </div>
                                </Content>
                                <SessionTimeout />
                            </Layout>
                        )}
                    </Router>
                </AppContext.Provider>
            )}

            {authInProgress && (
                <div style={{ position: "absolute", top: "45%", left: "30%", fontSize: 20 }}>
                    <div>
                        <div style={{ marginLeft: "40%", marginBottom: 15 }}>
                            {/* <Image width={40} src="../assets/message-bot-solid.png" preview={false} /> */}
                        </div>
                        <Space>
                            <h4>Just a moment, we are getting things ready for you</h4>
                            <Loading style={{ width: 100, height: 30, margin: "5px 0px" }} />
                        </Space>
                    </div>
                </div>
            )}
        </ConfigProvider>
    )
}

export default App
